/**
 * Toggle navigation
 */
jQuery(document).ready(function() {
    var App = {

        /**
         * Cache elements
         */
        boot: function() {
            App.toggleNavButton = jQuery('.nav-toggle');
            App.toggleSubNavButton = jQuery('.menu-item-has-children > a');
            App.megaMenu = jQuery('.mega-menu, .mega-menu > ul > li');
            App.slidshows = jQuery('.slider__slides');
            App.imageSlideshows = jQuery('.image-slider__slides');
            App.lightboxLinks = jQuery('a[href$=".jpg"],a[href$=".jepg"],a[href$=".png"]');
            App.masonries = jQuery('.blog .posts__list, .category .posts__list, .post-type-archive-event .posts__list, .tax-event-category .posts__list');
            App.accordion = jQuery('.accordion__title');
            App.contentSlider = jQuery('.activity-slider__posts, .news-slider__posts');

            App.activityFilterForm = jQuery('.activity-filter');
            App.activityFilter = jQuery('.activity-filters__filter-option');
            App.activityChildFilter = jQuery('.activity-child-filter');
            App.activityResultCounter = jQuery('.activity-post-count__counter');
            App.activityResult = jQuery('.activities__list');
        },

        /**
         * Bindings
         */
        init: function() {
            App.boot();

            App.toggleNavButton.click(function(e) {
                e.preventDefault();
                App.toggleNav(jQuery(this));
            });

            App.toggleSubNavButton.click(function(e) {
                if (940 <= jQuery(window).width()) {
                    return;
                }
                e.preventDefault();
                App.toggleSubNav(jQuery(this));
            });

            App.megaMenu.hover(
                function() {
                    if (940 > jQuery(window).width()) {
                        return;
                    }
                    App.megaMenuIn(jQuery(this));
                },
                function() {
                    if (940 > jQuery(window).width()) {
                        return;
                    }
                    App.megaMenuOut(jQuery(this));
                }
            );

            App.slidshows.each(function() {
                App.initSlideshow(jQuery(this));
            });

            App.imageSlideshows.each(function() {
                App.initImageSlideshow(jQuery(this));
            });

            App.lightboxLinks.fancybox();

            App.macyInit();

            App.accordion.click(function(e) {
                e.preventDefault();
                App.toggleAccordion(jQuery(this));
            });

            App.contentSlider.each(function() {
                App.initContentSlider(jQuery(this));
            });

            App.activityFilter.change(function() {
                App.toggleActivityFilter(jQuery(this));
            });

            App.activityFilterForm.submit(function(e) {
                e.preventDefault();
                App.filterActivities(false);
            });

            if (0 < App.activityFilter.filter(':checked').length) {
                App.filterActivities(false);
                App.activityFilter.filter(':checked').each(function() {
                    App.toggleActivityFilter(jQuery(this));
                });
            }
        },

        /**
         * Mobile nav
         */
        toggleNav: function(element) {
            jQuery('body').toggleClass('nav--visible');
        },

        /**
         * Mobile subnav
         */
        toggleSubNav: function(element) {
            element
                .parent()
                .toggleClass('subnav--visible')
                .find('> ul')
                .slideToggle();
        },

        /**
         * Mega menu
         */
        megaMenuIn: function(element) {
            var megaMenu = element.closest('.mega-menu'),
                nav = megaMenu.find('> ul'),
                container = nav.find('> li > ul:visible');
            nav.removeAttr('style');
            if (nav.height() < container.outerHeight()) {
                nav.height(container.outerHeight());
            }
        },

        /**
         * Mega menu
         */
        megaMenuOut: function(element) {
            var megaMenu = element.closest('.mega-menu'),
                nav = megaMenu.find('> ul'),
                container = nav.find('> li > ul:visible');

            nav.removeAttr('style');
            if (jQuery('.mega-menu > ul ul:visible').length) {
                if (nav.height() < container.outerHeight()) {
                    nav.height(container.outerHeight());
                }
            }
        },

        /**
         * Slider
         */
        initSlideshow: function(slider) {
            if (1 > slider.children().length) {
                return;
            }

            var currentSlider = slider.closest('.slider'),
                pager = currentSlider.find('.slider__page'),
                options = {
                    dots: false,
                    arrows: true,
                    prevArrow: '<button type="button" class="slick-prev"></button>',
                    nextArrow: '<button type="button" class="slick-next"></button>'
                };

            if (slider.data('autoplay')) {
                options.autoplay = true;
                options.autoplaySpeed = 5500;
                options.pauseOnHover = false;
            }

            slider.slick(options).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                pager.filter(':eq(' + nextSlide + ')').trigger('click');
            });

            pager.click(function() {
                var obj = jQuery(this);
                pager.filter('.slider__page--active').removeClass('slider__page--active');
                slider.slick('slickGoTo', obj.index());
                obj.addClass('slider__page--active');
            });
        },

        /**
         * Image slides
         */
        initImageSlideshow: function(slider) {
            if (2 > slider.children().length) {
                return;
            }

            var options = {
                dots: false,
                arrows: true,
                prevArrow: '<button type="button" class="slick-prev"></button>',
                nextArrow: '<button type="button" class="slick-next"></button>',
                adaptiveHeight: true
            };

            if (slider.data('autoplay')) {
                options.autoplay = true;
                options.autoplaySpeed = 5500;
                options.pauseOnHover = false;
            }

            slider.slick(options);
        },

        /**
         * Masonry
         */
        macyInit: function() {
            if (1 > App.masonries.length) {
                return;
            }
            Macy({
                container: '.blog .posts__list, .category .posts__list, .post-type-archive-event .posts__list, .tax-event-category .posts__list',
                trueOrder: true,
                waitForImages: true,
                margin: {
                    x: 20,
                    y: 50
                },
                columns: 3,
                breakAt: {
                    980: 3,
                    782: 2,
                    640: 1
                }
            });
        },

        /**
         * Accordion
         */
        toggleAccordion: function(toggle) {
            var container = toggle.closest('.accordion');
            var content = container.find('.accordion__content');
            container.toggleClass('accordion--open');
            content.slideToggle();
        },

        /**
         * Content slider
         */
        initContentSlider: function(element) {
            element.slick({
                infinite: true,
                dots: false,
                slidesToShow: 3,
                slidesToScroll: 3,
                prevArrow: '<button type="button" class="slick-prev"></button>',
                nextArrow: '<button type="button" class="slick-next"></button>',
                responsive: [
                    {
                        breakpoint: 980,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        },

        toggleActivityFilter: function(filter) {
            var children = App.activityChildFilter.filter('.child-filters-' + filter.val());
            if (filter.prop('checked')) {
                children.removeClass('hidden');
            } else {
                children.find('input[type="checkbox"]').prop('checked', false);
                children.addClass('hidden');
            }
            App.filterActivities(filter);
        },

        filterActivities: function(filter) {
            App.activityResult.addClass('loading');

            jQuery.post(
                ajaxurl,
                {
                    action: 'filter-activities',
                    request: App.activityFilterForm.serialize()
                },
                function(response) {
                    App.activityResult.removeClass('loading').html(response.data.body);
                    App.activityResultCounter.text(response.data.total);
                }
            );
        }
    };

    App.init();

    /**
     * Parallax
     */
    var sliderImage = jQuery('.slider__slide__image');

    // Detect request animation frame
    var scroll =
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        window.oRequestAnimationFrame ||

        // IE Fallback, you can even fallback to onscroll
        function(callback) {
            window.setTimeout(callback, 1000 / 60);
        };

    function loop() {
        var top = window.pageYOffset;

        sliderImage.css({
            top: top * 0.5
        });

        // Recall the loop
        scroll(loop);
    }

    // Call the loop for the first time
    loop();
});
